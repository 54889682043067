import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-[90%] mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[20px] md:text-start text-center text-white">
                    <div className="flex md:flex-row flex-col justify-center space-x-10">
                        <div className="md:w-1/2 w-full text-center md:text-start p-4">
                            <h1 className="text-[30px] md:text-[3em] lg:text-[3em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                            <p className="text-center md:text-start ">{rpdata?.dbValues?.[0].description}</p>
                            <ButtonContent btnStyle="three" />
                        </div>

                        <div className="flex justify-center items-center">
                            <img
                                alt="year"
                                src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F8%20Years.png?alt=media&token=f9e76f3a-0400-48b4-bb29-e1a3ed8f6d76"
                                className="md:w-[280px] md:-mt-20 mt-0 w-[150px]"
                                />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default HeroVideo;